/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"

import { makeStyles } from "@material-ui/core/styles"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import Grow from "@material-ui/core/Grow"

import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded"
import StopRoundedIcon from "@material-ui/icons/StopRounded"

import { connect } from "react-redux"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}))

const CustomPlaybutton = ({
  currentPlayingSrc,
  preftheme,
  src,
  compact,
  setsrc,
}) => {
  const classes = useStyles()

  /*
    //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = preftheme == 'dark';
   
     const theme = React.useMemo(
       () =>
         createMuiTheme({
           palette: {
             type: prefersDarkMode ? 'dark' : 'light',
           },
         }),
       [prefersDarkMode],
     );
     <ThemeProvider theme={theme}>
      </ThemeProvider>


<Grow >
</Grow>
*/
  var newSrc = (src == currentPlayingSrc) ? "" : src;
  if (compact == false) {
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={
          currentPlayingSrc != src ? (
            <PlayArrowRoundedIcon />
          ) : (
            <StopRoundedIcon />
          )
        }
        onClick={() => setsrc(newSrc)}
      >
        {currentPlayingSrc != src ? "Reproducir" : "Detener"}
      </Button>
    )
  } else {
    return (
      <IconButton
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => setsrc(newSrc)}
      >
        {currentPlayingSrc != src ? (
          <PlayArrowRoundedIcon />
        ) : (
          <StopRoundedIcon />
        )}
      </IconButton>
    )
  }
}

CustomPlaybutton.propTypes = {
  currentPlayingSrc: PropTypes.string.isRequired,
  preftheme: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ currentPlayingSrc, preftheme }, ownProps) => {
  const { src, compact } = ownProps
  return { currentPlayingSrc, preftheme, src, compact }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  console.log("PLAYBUTTON mapdispatchtoprops", ownProps)
  return {
    setsrc: (newsrc) =>
      dispatch({
        type: `CURRENTLYPLAYING/SETSRC`,
        payload: { src: newsrc },
      }),
  }
}

const ConnectedPlaybutton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomPlaybutton)

const PlayButton = ({ src, compact }) => (
  <ConnectedPlaybutton src={src} compact={compact} />
)

PlayButton.propTypes = {
  src: PropTypes.string.isRequired,
  compact: PropTypes.bool.isRequired,
}

export default PlayButton
