import React from "react"
import Badge from '@material-ui/core/Badge';
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import PlayButton from "./playbutton"

import cx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import FavoriteBorderRounded from '@material-ui/icons/FavoriteBorderRounded';
import Share from '@material-ui/icons/Share';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useSlopeCardMediaStyles } from '@mui-treasury/styles/cardMedia/slope';
import { useN01TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n01';
import TextInfoContent from '@mui-treasury/components/content/textInfo';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 304,
    margin: 'auto',
  },
  content: {
    padding: 24,
  },
  avatar: {
    width: 50,
    height: 50,
    border: '2px solid #fff',
    margin: '-48px 32px 0 auto',
    '& > img': {
      margin: 0,
    },
  },
}))

export default function Component(props) {
  console.log("carditem", props)
  const cardStyles = useStyles();
  const mediaStyles = useSlopeCardMediaStyles();
  const shadowStyles = useSoftRiseShadowStyles();
  const textCardContentStyles = useN01TextInfoContentStyles();
  const theme = useTheme()
  /*
  <Badge badgeContent={props.count} color="error">
  </Badge>

  <Card className={`${classes.root} ${classes.cardbg}`} elevation={3}>
  <AniLink paintDrip to={props.url} variant="body2">
  <CardMedia
  component="img"
  className={classes.media}
  image={props.img}
  title={props.title}
  >
  </CardMedia>
  </AniLink>
  <CardContent>
  <PlayButton src={props.mediaurl} compact={true} />
  <AniLink paintDrip to={props.url} variant="body2">
  <Typography variant="body2" color="textSecondary" component="p">
  {" "}
  {props.title}{" "}
  </Typography>
  <Typography variant="body2" color="textSecondary" component="p">
  {" "}
  {props.subtitle}{" "}
  </Typography>
  </AniLink>
  </CardContent>
  </Card>

  */
  return (
    
   <div class="card">
    <div class="card-image">
      <figure class="image is-4by3">
        <img src={props.img} alt={props.title} />
      </figure>
    </div>
    <div class="card-content">
      
  
      <div class="content">
      {props.title}
      <br />
      {props.subtitle}
      </div>
      <footer class="card-footer">
    <p class="card-footer-item">
      <span>
      <PlayButton src={props.mediaurl} compact={true} />  
      </span>
    </p>
    <p class="card-footer-item">
      <span>
        Share on <a href="#">Facebook</a>
      </span>
    </p>
  </footer>
    </div>
  </div>

  )
}
