import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardItemEpisode from "../components/carditemepisode"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import PlayButton from "../components/playbutton"
import ShowHeader from "../components/showheader"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: "100%",
    paddingTop: 0, // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

export default function Component(props) {
  console.log("podcast", props)
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  var node = props.data.directusPodcast
  //<Badge badgeContent={node.episodes.length} color="error">
  //</Badge>
  return (
    <div>
      <SEO title={node.title} />

      <Breadcrumbs aria-label="breadcrumb">
        <AniLink cover direction="left" to="/" variant="body2">
          Home
        </AniLink>
        <AniLink cover direction="left" to="/shows" variant="body2">
          Shows
        </AniLink>
        <Typography color="textPrimary">{node.title}</Typography>
      </Breadcrumbs>

      <ShowHeader title={node.title} subtitle={node.subtitle} img={node.image.localFile.childImageSharp.fluid.src}></ShowHeader>

      <Container component="main" maxWidth={false}>
      <div className="columns is-mobile">
            {node.episodes.map((episode, index) => {
              console.log("episode", episode, index)
              var subtitle = new Date(episode.media_length * 1000)
                .toISOString()
                .substr(11, 8)
              var episodePath = `/episodes/${episode.slug}`
              return (
                <div className="column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd" key={index}>
                  <CardItemEpisode
                    url={episodePath}
                    mediaurl={episode.url}
                    backgroundColor={node.color}
                    img={episode.image.localFile.childImageSharp.fluid.src}
                    title={episode.title}
                    subtitle={subtitle}
                  ></CardItemEpisode>
                </div>
              )
            })}
          </div>
      </Container>
    </div>
  )
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query($id: String) {
    directusPodcast(id: { eq: $id }) {
      title
      subtitle
      url
      color
      slug
      episodes {
        id
        title
        subtitle
        url
        slug
        media_length
        image {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      image {
        localFile {
          url
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
